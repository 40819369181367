import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import SuperListedProductManagement from "../../domain/superListedProductManagement"
import "./styles.scss"

const index = ({ location }) => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout
        selectedPage={<SuperListedProductManagement location={location} />}
      />
    </div>
  )
}

export default index
