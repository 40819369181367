import { Button, Switch, Tooltip, Table, InputNumber } from "antd"
import { navigate } from "gatsby"
import React, { useContext, useState } from "react"
import { ListProductsContext } from ".."
import { itemRender } from "../../../customUtil/table"
import "../style.scss"
import NoImg from "../../../images/no_image.jpg"
import queryString from "query-string"
import EditPickUpRank from "./EditPickUpRank"
import HidenPrice from "./HidenPrice"

const DataTable = () => {
  const {
    dataProducts,
    loading,
    selectedRowKeys,
    setSelectedRowKeys,
    setListProductId,
    total,
    tableParams,
    location,
  } = useContext(ListProductsContext)
  const query = queryString.parse(location.search)

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys)
    const product_id = Array.from(
      new Set(selectedRows.map((item) => item.product_id))
    )
    setListProductId(product_id)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columns = [
    {
      title: "商品画像",
      dataIndex: "thumbnail",
      width: 100,
      render: (_, record) => (
        <img
          style={{ width: "60px", height: "35px", objectFit: "cover" }}
          alt={"thumbnail"}
          src={record?.product?.thumbnail || NoImg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImg
          }}
        />
      ),
    },
    {
      title: "販売規格",
      dataIndex: "sales_standard",
      width: 120,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "自社品番JANコード",
      dataIndex: "hs_code",
      key: "hs_code",
      width: 200,
      sorter: true,
      sortOrder: query?.sorterField == "hs_code" ? query?.order : null,
      render: (_, record) => (
        <Tooltip title={record.JANcode}>
          <div className="line-clamp-1">{record.JANcode}</div>
        </Tooltip>
      ),
    },
    {
      title: "掲載企業名",
      dataIndex: "store_name",
      width: 150,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "ステータス",
      dataIndex: "status",
      width: 150,
      render: (text) => <div className="textCustom">{text}</div>,
    },
    {
      title: "登録日",
      dataIndex: "created_at",
      width: 150,

      sorter: true,
      sortOrder: query.sorterField == "created_at" ? query.order : null,
      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "更新日",
      dataIndex: "updated_at",
      sorter: true,
      sortOrder: query.sorterField == "updated_at" ? query.order : null,
      width: 150,
      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "カテゴリ（大）",
      dataIndex: "category_large",

      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "カテゴリ（中）",
      dataIndex: "category_middle",

      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "カテゴリ（小）",
      dataIndex: "category_small",

      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "商品名",
      dataIndex: "title",

      render: (text) => (
        <Tooltip title={text}>
          <div className="line-clamp-1">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: "PICK UP商品",

      width: "180px",
      // render: (text) => <div className="textCustom">{text}</div>,
      render: (_, record, index) => {
        const obj = {
          children: (
            <div className="w-[110px] flex gap-[10px] items-center">
              <EditPickUpRank record={record} />
            </div>
          ),
          props: {},
        }
        if (index == 0) {
          const length = dataProducts.filter((el) => el.title == record.title)
            .length
          obj.props.rowSpan = length
        }
        if (index > 0) {
          if (dataProducts[index].title == dataProducts[index - 1].title) {
            obj.props.rowSpan = 0
          } else {
            const length2 = dataProducts.filter(
              (el) => el.title == record.title
            ).length
            obj.props.rowSpan = length2
          }
        }
        return obj
      },
    },
    {
      title: "価格非公開",
      align: "center",

      width: "130px",
      // render: (text) => <div className="textCustom">{text}</div>,
      render: (_, record) => <HidenPrice record={record} />,
    },
    {
      title: "卸値",
      dataIndex: "wholesale_price",
      fixed: "right",

      width: 200,
      render: (text) => <div className="textCustom line-clamp-1">{text}</div>,
    },
    {
      title: "販売価格",
      dataIndex: "retail",
      fixed: "right",

      width: 200,
      sorter: true,
      sortOrder: query.sorterField == "retail" ? query.order : null,
      render: (_, record) => (
        <Tooltip title={record.selling_price}>
          <div className="line-clamp-1">{record.selling_price}</div>
        </Tooltip>
      ),
    },
    {
      title: "編集",
      fixed: "right",
      key: "record",
      width: 100,

      render: (_, record) => (
        <Button
          onClick={() => {
            navigate(`detail?product_id=${record?.product?.id}`)
          }}
        >
          編集
        </Button>
      ),
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: pagination.current,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    if (sorter.order) {
      newQueryStr.sorterField = sorter.field
      newQueryStr.order = sorter.order
    }
    navigate(
      `/super-listed-product-management/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }
  return (
    <div className="customTable">
      <Table
        scroll={{ x: 2500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataProducts}
        locale={{
          emptyText: "検索結果は０です。",
        }}
        loading={loading}
        onChange={handleTableChange}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       console.log(event.target)
        //       if (
        //         event.target?.name == "pick_up" ||
        //         event.target?.tagName == "svg"
        //       ) {
        //         return
        //       }

        //       navigate(`detail?product_id=${record?.product?.id}`)
        //     },
        //   }
        // }}
        pagination={{
          ...tableParams.pagination,
          total: total,
          itemRender: itemRender,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
      />
    </div>
  )
}

export default DataTable
